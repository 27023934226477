import React from 'react'
export const Process = () => (
  <section className="process">
    <div className="block">
      <div className="about__text">
        <p>
          <strong>
            Wij willen u graag informeren over onze manier van werken, zodat u
            een beeld kunt vormen van de gang van zaken rondom uw aanvraag en
            uitvoering van een klus.
          </strong>
        </p>
        <h3>Offerte aanvragen</h3>

        <p>
          U kunt een offerte aanvragen via deze website. Op basis van uw
          beschrijving van de klus maken wij een concept-offerte. Deze offerte
          bestaat uit een <span className="underline">schatting</span> van het
          aantal manuren, de materiaalkosten en de doorlooptijd. Deze offerte is
          vrijblijvend en zonder kosten.
        </p>
        <p>
          U kunt ook een offerte aanvragen via een afspraak waarbij wij
          langskomen. Op locatie bepalen wij, in overleg met u, wat er precies
          moet gebeuren, wat de kosten zijn en wat de doorlooptijd is (zonder
          eventuele vertragingen). Deze offerte is{' '}
          <strong className="underline">wel</strong> verbonden aan kosten.
        </p>

        <h3>Overeenkomst</h3>

        <p>
          Wanneer u akkoord gaat met de definitieve offerte wordt er een
          overeenkomst opgesteld. In de overeenkomst leest u de beschrijving van
          de werkzaamheden, de start- en einddatum, de kosten van de materialen
          en de arbeidsuren en de algemene voorwaarden. De klus kan beginnen als
          beide partijen de overeenkomst hebben getekend.
        </p>

        <h3>Materiaal inkopen</h3>

        <p>
          Aan de hand van de overeenkomst kopen wij materialen in. Deze zijn van
          hoge kwaliteit en worden ingekocht bij de groothandel.
        </p>

        <h3>Voorbereiding</h3>

        <p>
          <strong className="italic">Sloopwerk</strong>
        </p>
        <p>
          Een goede voorbereiding is het halve werk. Dit geldt ook voor
          sloopwerk. Wij staan garant voor een zorgvuldige uitvoering: geen
          onnodige schade, zo min mogelijk overlast aan geluidshinder of vuil.
          Afvalresten voeren wij af in speciale zakken of een, door ons
          gehuurde, container.
        </p>

        <p>
          Na de sloop beginnen wij aan de voorbereidingen voor de klus. Deze
          bestaan over het algemeen uit schoonmaken, voorbehandelen en
          reparaties, maar dit kan natuurlijk per klus verschillen.
        </p>

        <p>
          <strong className="italic">Grove fase</strong>
        </p>

        <p>
          De werkelijke klus begint met de grove fase. De
          electriciteitsbedradingen, waterleidingen en gas- leidingen worden
          gelegd. Kabels en bedrading wordt weggewerkt. Eventuele vloer- en/of
          wandverwarmingselementen en tussenmuren worden geplaatst. Wanneer deze
          fase is afgerond wordt u hiervan op de hoogte gesteld.
        </p>

        <p>
          <strong className="italic">Fijne fase</strong>
        </p>

        <p>
          We kunnen nu beginnen aan werkzaamheden zoals stucwerk, behangen,
          schilderen en betegelen. In deze fase wordt alles met precisie
          geplaatst, bewerkt en afgewerkt. Als deze werkzaamheden zijn afgerond
          wordt gechecked of alles werkt, of er nog aanpassingen moeten worden
          gedaan en of er geen fouten zijn gemaakt.
        </p>

        <p>
          <strong className="italic">Schoonmaak</strong>
        </p>

        <p>
          Wanneer de klus is afgerond worden afvalresten door ons afgevoerd. Wij
          zorgen ervoor dat de locatie van de klus schoon en gebruiksklaar wordt
          achtergelaten.
        </p>

        <p>
          <strong className="italic">Laatste check</strong>
        </p>

        <p>
          Twee weken nadat de klus is gerealiseerd, nemen wij contact met u op
          om te informeren naar uw mate van tevredenheid. Indien u nog
          aanpassingen wenst, bekijken we de mogelijheid daarvan. Indien u
          klachten heeft, zullen wij die oplossen.
        </p>
      </div>
    </div>
  </section>
)
