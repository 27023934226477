import React from "react"
import Layout from "../components/layout"
import { Process } from "../components/process/Process"
import SEO from "../components/seo"
import { Contact } from "../components/contact/Contact"

const WerkWijzePage = () => (
  <Layout>
    <SEO title="Werkwijze" />
    <Process />
    <Contact />
  </Layout>
)

export default WerkWijzePage
